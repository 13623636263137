.features {
    &-cat-list {
        position: relative;
        padding: 40px 50px;
        transition: $trans-25;
        @media screen and (max-width: 992px) {
            padding: 30px 20px;
        }
        &:before, &:after {
            content: '';
            position: absolute;
            left: 0;
            height: 1px;
            width: 100%;
            background-image: linear-gradient(to right, #188024, #29C5D4);
        }
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
        ul li a {
            display: flex;
            gap: 12px;
            align-items: center;
            color: $theme-text;
            font-size: 22px;
            font-weight: $font-medium;
            position: relative; 
            @media screen and (max-width: 992px) {
                font-size: $font-rg;
            }
            &:after {
                content: "";
                background-image: url('../img/vectors/hover-underline.svg');
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                bottom: -21px;
                left: 0;
                width: 100%;
                height: 7px;
                opacity: 0;
                transition: $trans-25;
            }
            &:hover {
                color: $theme-color-3;
                svg {
                    path {
                        fill: $theme-color-3;
                    }
                }
                &:after {
                    bottom: -17px;
                    opacity: 1;
                    @media screen and (max-width: 992px) {
                        bottom: -7px;
                    }
                }
            }
        }
    }
}