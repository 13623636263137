.theme-icon-box {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: $padding-xl;
    border: 1px solid $theme-border;
    border-radius: $rounded;
    width: 100%;
    height: 100%;
    > * {
        transition: $trans-25;
    }
    &.gap-30 {
        gap: 30px;
    }
    &.gap-24 {
        gap: 24px;
    }
    .icon-box-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .title {
            font-weight: var(--font-semi);
            font-size: var(--font-lg);
            color: var(--theme-color-3);
        }
        .text {
            color: var(--theme-text);
            font-size: var(--font-rg);
        }
    }
    &.style-1 {
        position: relative;
        border: 1px solid #101736;
        color: var(--theme-white);
        z-index: 0;
        gap: 30px;

        .bg-slight {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .theme-icon {
            margin-top: -90px;
        }
        .title {
            color: var(--theme-white);
            position: relative;
            padding-bottom: 16px;
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 200px;
                height: 1px;
                background-color: var(--theme-color-1);
            }
        }
        .text {
            color: var(--theme-color-4);
        }
    }
    &.style-2 {
        padding: 0;
        border: none;
        .icon-box-content {
            .title {
                font-size: var(--font-lg);
            }
        }
    }
    &.style-3 {
        padding: 40px 24px;
        border: 1px soild #E3F0FF;
        .icon-box-content {
            .title {
                font-size: var(--font-lg);
            }
        }
    }
}