.counter-section {
    position: relative;
    z-index: 0;
    background: linear-gradient(180deg, rgba(238, 246, 255, 0.6) 0%, rgba(238, 246, 255, 0.6) 100%);
    padding: 45px 0;
    .bg-layer {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        @extend .pattern-bg;
        padding: 0;
    }
    &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        height: 1px;
        width: 100%;
        background: linear-gradient(251.58deg, #CAE9F5 0.07%, rgba(37, 148, 195, 0.4) 25.46%, #CAE9F5 75.02%);
    }
    &:before {
        top: 0;
    }
    &:after {
        bottom: 0;
    }
}

.counterUp {
    padding: 15px 0;
    .counter {
        &Number {
            font-size: $font-3x;
            font-weight: $font-bold;
            background: linear-gradient(242.88deg, #2DB4EE 0.07%, #1D769C 72.07%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
            margin-bottom: 10px;
            line-height: 1.4;
        }
        &Label {
            font-size: 20px;
            font-weight: $font-medium;
        }
    }
}

.counterPoint {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    border-radius: 10px;
    border: 1px solid #E3F0FF;
    .count {
        display: flex;
        align-items: center;
        gap: 16px;
        .icon {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            background-color: #4FD1D9;
            color: #fff;
        }
        .number {
            font-weight: $font-bold;
            color: $theme-color-3;
            font-size: $font-3x;
        }
    }
    .text {
        font-size: $font-md;
    }
}