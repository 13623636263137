.theme-icon {
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    &.icon-xs {
        height: 20px;
        width: 20px;
    }
    &.icon-sm {
        height: 35px;
        width: 35px;
    }
    &.icon-md {
        height: 44px;
        width: 44px;
        font-size: var(--font-lg);
    }
    &.icon-lg {
        height: 64px;
        width: 64px;
        font-size: var(--font-xl);
    }
    &.icon-xl {
        height: 80px;
        width: 80px;
        font-size: var(--font-2x);
    }
    &.icon-2x {
        height: 100px;
        width: 100px;
        font-size: var(--font-3x);
    }
    &.icon-110 {
        height: 110px;
        width: 110px;
        font-size: var(--font-3x);
    }
    &.icon-120 {
        height: 120px;
        width: 120px;
        font-size: var(--font-3x);
    }
    &.icon-3x {
        height: 130px;
        width: 130px;
        font-size: var(--font-4x);
    }
    &.icon-primary {
        background-color: var(--theme-color-1);
        color: var(--theme-white);
    }
    &.icon-dark {
        background-color: #141C2F;
        color: var(--theme-white);
    }
    &.icon-gradient {
        background: linear-gradient(242.88deg, #2DB4EE 0.07%, #1D769C 72.07%);
        border: 3px solid #171F42;
    }
    &.border-0 {
        border: 0;
    }
    &.icon-white {
        background-color: var(--theme-white);
    }
}