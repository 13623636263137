.tpnb {
    padding: 30px 90px 0 90px;
    z-index: 999;
    transition: $trans-50;

    &.is-stickyActive {
        padding-top: 5px;
        padding-bottom: 5px;
        transform: translateY(-100%);

        .tpnb-mobile {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.sticky-one {
        transition: none;

        &.is-stickyActive {
            transform: translateY(100%);
            transition: $trans-50;
        }
    }

    @media screen and (max-width: 1600px) {
        padding: 30px 30px 0 30px;
    }

    @media screen and (max-width: 480px) {
        padding: 30px 15px 0 15px;
    }

    .tpnb-mobile {
        display: none;
        align-items: center;
        gap: 16px;
        padding-top: 10px;
        padding-bottom: 10px;

        .nav-actions {
            padding: 0;
        }

    }

    .tpnb-wrapper {
        display: flex;
        align-items: center;
        gap: 32px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .position {
        &-left {
            margin-left: 0;
            margin-right: auto;
        }

        &-center {
            margin-left: auto;
            margin-right: auto;
        }

        &-right {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .nav-logo {
        display: inline-flex;

        a {
            display: inline-flex;
            align-items: center;
            gap: 8px;

            img {
                max-height: 48px;
                width: auto;
            }
        }

        &.icon-logo {
            a {
                img {
                    height: 24px;
                    width: auto;
                }
            }
        }
    }

    .nav-bars {
        cursor: pointer;

        span {
            display: block;
            height: 2px;
            width: 20px;
            background-color: var(--theme-color-1);

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }

    .nav-menu {
        font-size: var(--font-rg);
        padding-left: 40px;

        @media screen and (max-width: 1600px) {
            padding-left: 0;
        }

        >ul {
            display: flex;
            align-items: stretch;

            li {
                position: relative;

                &.active {
                    a {
                        background-color: rgba($theme-color-1, 0.1);
                        color: var(--theme-color-1);
                    }
                }

                &.menu-item-has-children {
                    .sub-menu {
                        padding: 8px 0px;
                        transform: translateY(10px);
                        transition: $trans-25;

                        li a {
                            display: flex;
                            padding: 8px 16px;
                            transition: $trans-25;

                            &:hover {
                                color: var(--theme-color-3);
                            }
                        }
                    }

                    &:hover {
                        >.sub-menu {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0px);
                        }
                    }

                    &.mega-menu {
                        .sub-menu {
                            flex-direction: row;
                            width: 720px;

                            @media screen and (max-width:1200px) {
                                width: 600px;
                            }

                            >* {
                                flex-grow: 1;
                                flex-basis: 50%;
                                max-width: 100%;
                            }
                        }

                        &.mega-menu {
                            &-sm {
                                .sub-menu {
                                    width: 480px;
                                }
                            }

                            &-lg {
                                .sub-menu {
                                    width: 960px;

                                    @media screen and (max-width:1200px) {
                                        width: 800px;
                                    }
                                }
                            }

                            &-full {
                                position: static;

                                .sub-menu {
                                    width: 100%;
                                    left: 0;
                                    top: 100%;
                                }
                            }
                        }
                    }
                }

                a {
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                    padding: 16px 16px;
                    color: var(--theme-text);
                    height: 100%;
                    font-size: var(--font-md);
                    font-weight: $font-regular;

                    @media screen and (max-width: 1600px) {
                        font-size: var(--font-rg);
                        padding: 16px 10px;
                    }

                    span {
                        position: relative;
                        display: inline-block;

                        &:after {
                            content: "";
                            background-image: url('../img/navmenu.svg');
                            background-position: center center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            position: absolute;
                            bottom: -8px;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            opacity: 0;
                            transition: $trans-25;
                        }
                    }

                    svg path {
                        transition: $trans-25;
                    }

                    @media screen and (max-width: 992px) {
                        display: flex;
                    }

                    &.active {
                        color: var(--theme-color-3);

                        span:after {
                            opacity: 1;
                        }
                    }

                }

                &:hover {
                    >a {
                        color: var(--theme-color-3);

                        svg path {
                            stroke: $theme-color-3;
                        }
                    }

                    span:after {
                        bottom: -4px;
                        opacity: 1;
                    }
                }
            }
        }

        &.position-center {
            >ul {
                display: flex;
                justify-content: center;
            }
        }
    }

    a {
        span {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            i,
            .icon {
                font-size: 21px;
            }
        }

        .badge {
            background-color: red;
            color: var(--theme-white);
            height: 16px;
            min-width: 16px;
            border-radius: $rounded-full;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
            position: absolute;
            right: -12px;
            top: -12px;
            padding: 0 4px;
        }

        .tooltip {
            display: inline-block;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            top: calc(100% + 8px);
            left: 50%;
            transform: translateX(-50%);
            padding: 3px 8px;
            padding-bottom: 6px;
            background-color: var(--theme-color-1);
            color: var(--theme-white);
            border-radius: $rounded;
            font-size: 12px;
        }

        &:hover {
            .tooltip {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 220px;
        padding: 8px 0px;
        background-color: var(--theme-white);
        display: flex;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;
        font-size: 14px;
        z-index: 9;

        li a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            margin: 0 8px;
            padding: 8px 8px;
            color: var(--theme-color-1);
            border-radius: $rounded;

            .badge {
                right: auto;
                left: 100%;
                top: -8px;
            }
        }

        .sub-menu {
            top: 0;
            left: 100%;

            &.position {
                &-left {
                    right: 100%;
                    left: auto;
                }
            }
        }

        &.position {
            &-center {
                left: 50%;
                transform: translateX(-50%);
            }

            &-left {
                right: 0;
                left: auto;
            }

            &-top {
                bottom: 100%;
                top: auto;
            }
        }

        .menu-title {
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: bold;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin: 0 8px;
        }
    }

    .nav-actions {
        display: flex;
        padding: 7px 16px;
        gap: 10px;
        font-size: 14px;

        .action-dropdown {
            position: relative;

            &:hover {
                >.sub-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .nav-close {
        display: none;
    }
}

@mixin responsiveNavbar {
    .tpnb-wrapper {
        display: block;
        transform: translateX(-100%);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: var(--theme-white);
        transition: all 0.5s linear;
        overflow-y: auto;
        min-height: 100vh;

        &.show {
            transform: translateX(0);
            transition: all 0.5s linear;
        }

        >* {
            order: 3;
        }

        .nav-avatar {
            margin-left: auto;
        }
    }

    .nav-menu {
        flex-basis: 100%;
        width: 100%;
    }

    .nav-actions {
        flex-wrap: wrap;
        width: 100%;
    }

    .theme-logo {
        padding: 16px;

        img {
            max-width: 130px;
        }
    }

    .nav-avatar {
        padding: 16px;
        padding-left: 0;
    }

    .tpnb-mobile {
        display: flex;

        >* {
            order: 0;
            width: auto;
        }

        .theme-logo {
            padding: 16px 0;

            @media screen and (max-width:480px) {
                max-width: 100px;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }

    .nav-close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 56px;
        width: 56px;
        cursor: pointer;
    }
}

@mixin responsiveNavMenu {
    flex-basis: 100%;
    width: 100%;

    ul {
        flex-direction: column;

        >li {
            &:before {
                content: "";
                position: absolute;
                right: 0;
                height: 100%;
                width: 40px;
                cursor: pointer;
            }
        }

        li a {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        li.menu-item-has-children {
            &.mega-menu {
                .sub-menu {
                    display: none;
                    width: 100% !important;
                    flex-wrap: wrap;

                    &.show {
                        display: flex;
                    }
                }
            }
        }
    }

    .sub-menu {
        position: relative;
        opacity: 1;
        visibility: visible;
        display: none;

        &.show {
            display: flex;
            transform: translateY(0px) !important;
        }

        .sub-menu {
            opacity: 1;
            visibility: visible;
            left: 0;
        }
    }
}

.nav-collapse {
    &-sm {
        @media screen and (max-width: 768px) {
            @include responsiveNavbar;
        }
    }

    &-md {
        @media screen and (max-width: 992px) {
            @include responsiveNavbar;
        }
    }

    &-lg {
        @media screen and (max-width: 1200px) {
            @include responsiveNavbar;
        }
    }
}

.navmenu-collapse {
    &-sm {
        @media screen and (max-width: 768px) {
            @include responsiveNavMenu();
        }
    }

    &-md {
        @media screen and (max-width: 992px) {
            @include responsiveNavMenu();
        }
    }

    &-lg {
        @media screen and (max-width: 1200px) {
            @include responsiveNavMenu();
        }
    }
}