// Inner Page -> Page Title
.page-title {
    padding-top: 90px;

    &.px-sm {
        padding-left: 40px;
        padding-right: 40px;
        @media screen and (max-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .breadcrumb {
        margin-bottom: 16px;
        justify-content: center;
    }

    &-headline {
        font-weight: $font-ebold;
        font-size: $font-6x;
        color: $theme-color-3;
        line-height: 1.2;
        margin-bottom: 14px;
        margin-bottom: 24px;
        @media screen and (max-width: 992px) {
            font-size: $font-3x;
        }
    }

    &-desc {
        p {
            font-size: 22px;
            @media screen and (max-width: 992px) {
                font-size: $font-md;
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }


            span {
                text-decoration: underline;
                font-weight: $font-medium;
            }
        }
    }
}

.single-heading {
    font-size: $font-2x;
    color: $theme-color-3;
    font-weight: $font-medium;
}

.inner-v1 {
    top: 35%;
    left: 6%;
}

.inner-v2 {
    top: 75%;
    left: 8%;
}

.inner-v3 {
    top: 42%;
    right: 8%;
}

.inner-v4 {
    top: 85%;
    right: 12%;
}

// Inner Page Styles
.ps-container {
    .ps{
        &-count {
            margin-bottom: 20px;
            span {
                margin-left: -13px;
                font-size: 22px;
                font-weight: var(--font-semi);
                color: #fff;
            }
        }
        &-title {
            font-size: var(--font-2x);
            font-weight: var(--font-bold);
            color: var(--theme-color-3);
            margin-bottom: 12px;
        }
        &-text {
            p:not(:last-child) {
                margin-bottom: 16px;
            }
            p {
                font-size: var(--font-md);
                font-weight: var(--font-medium);
            }
        }
    }
}
.ps-bgLeft {
    left: -210px;
}
.ps-bgRight {
    right: -170px;
}