.partners {
    &-title {
        font-size: var(--font-lg);
        margin-bottom: 60px;
        margin-left: auto;
        margin-right: auto;

        span {
            color: $theme-color-3;
            font-weight: $font-medium;
        }
    }

    &-logo {
        overflow: hidden;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: -60px;

        @media screen and (max-width: 1200px) {
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: -20px;
        }

        img {
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: 60px;
            max-width: 180px;

            @media screen and (max-width: 1200px) {
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 30px;
            }
        }

        &.style-2 {
            margin-left: -64px;
            margin-right: -64px;

            @media screen and (max-width: 1200px) {
                margin-left: -32px;
                margin-right: -32px;
            }

            @media screen and (max-width: 992px) {
                margin-left: -12px;
                margin-right: -12px;
            }

            img {
                margin-left: 64px;
                margin-right: 64px;

                @media screen and (max-width: 1200px) {
                    margin-left: 32px;
                    margin-right: 32px;
                }

                @media screen and (max-width: 992px) {
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }
    }

    &-action {
        margin-top: 50px;
    }
}

.partnersTab-item {
    margin-bottom: 80px;
    padding: 0 24px;
    line-height: 1.6;

    &.mb-0 {
        margin-bottom: 0;
    }

    &.salesVersion {
        &:hover {
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
        }
    }

    &.item-cover {
        padding: 60px 100px;
        background-color: #F3F8FF;
        border-radius: 20px;

        @media screen and (max-width:768px) {
            padding: 60px;
        }

        @media screen and (max-width:576px) {
            padding: 48px 24px;
        }
    }

    a {
        color: #4676F2;
        position: relative;
        border-bottom: 1px solid #4576f2;
    }

    .logo {
        margin-bottom: 24px;
        min-height: 70px;
    }

    .desc p:last-child {
        margin-bottom: 0;
    }
}