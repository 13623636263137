@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

// Fonts
$font-body : 'Inter',
sans-serif;
$font-heading : 'Inter',
sans-serif;

$font-light : 300;
$font-regular : 400;
$font-medium : 500;
$font-semi : 600;
$font-bold : 700;
$font-ebold : 800;

$font-italic : italic;

$font-xs : 12px;
$font-sm : 14px;
$font-rg : 16px;
$font-md : 18px;
$font-mdx: 22px;
$font-lg : 28px;
$font-xl : 32px;
$font-2x : 36px;
$font-3x : 40px;
$font-4x : 48px;
$font-5x : 64px;
$font-6x : 70px;
$font-7x : 80px;
$font-8x : 96px;

$line-height-body : 1.4;
$line-height-heading : 1.25;

// Colors
$theme-color-1 : #5E4BED;
$theme-color-2 : #2DB4EE;
$theme-color-3 : #21355C;
$theme-color-4 : #959CAA;
$theme-color-1-light : lighten($theme-color-1, 45%);
$theme-color-2-light : lighten($theme-color-2, 45%);

$theme-white : #FCFDFF;
$theme-black : #060414;
$theme-light : #F5F5F5;
$theme-dark : #080D23;

$theme-body-bg : #FFF;

$theme-text : #898D96;
$theme-text-light : rgba($theme-text, .75);

$theme-border : #E3F0FF;
$theme-border-on : $theme-color-1;

// Spacing
$padding : 16px;
$padding-sm : 08px;
$padding-md : 24px;
$padding-lg : 32px;
$padding-xl : 40px;
$padding-x : 30px;
$padding-y : 15px;
$padding-x-sm : 14px;
$padding-y-sm : 08px;
$padding-x-md : 32px;
$padding-y-md : 16px;
$padding-x-lg : 40px;
$padding-y-lg : 20px;

$section-gap : 85px;
$section-gap-xxs : 15px;
$section-gap-xs : 35px;
$section-gap-sm : 55px;
$section-gap-md : 105px;
$section-gap-lg : 120px;
$section-gap-xl : 140px;

// Shapes
$rounded : 5px;
$rounded-sm : 3px;
$rounded-md : 10px;
$rounded-lg : 20px;
$rounded-full : 10000px;


// Interactions
$trans-25 : all 0.25s linear;
$trans-50 : all 0.50s linear;
$trans-75 : all 0.75s linear;
$trans-100 : all 1.00s linear;
$trans-125 : all 1.25s linear;
$trans-150 : all 1.50s linear;
$trans-175 : all 1.75s linear;
$trans-200 : all 2.00s linear;

// Tags
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
}

:root {
    scroll-behavior: auto;
}

body {
    --font-body : #{$font-body};
    --font-heading : #{$font-heading};
    --font-light : #{$font-light};
    --font-regular : #{$font-regular};
    --font-semi : #{$font-semi};
    --font-bold : #{$font-bold};
    --font-xs : #{$font-xs};
    --font-sm : #{$font-sm};
    --font-rg : #{$font-rg};
    --font-md : #{$font-md};
    --font-lg : #{$font-lg};
    --font-xl : #{$font-xl};
    --font-2x : #{$font-2x};
    --font-3x : #{$font-3x};
    --font-4x : #{$font-4x};
    --font-5x : #{$font-5x};
    --font-6x : #{$font-6x};
    --font-7x : #{$font-7x};
    --font-8x : #{$font-8x};
    --line-height-body : #{$line-height-body};
    --line-height-heading : #{$line-height-heading};
    --theme-color-1 : #{$theme-color-1};
    --theme-color-2 : #{$theme-color-2};
    --theme-color-3 : #{$theme-color-3};
    --theme-color-4 : #{$theme-color-4};
    --theme-color-1-light : #{$theme-color-1-light};
    --theme-color-2-light : #{$theme-color-2-light};
    --theme-white : #{$theme-white};
    --theme-black : #{$theme-black};
    --theme-light : #{$theme-light};
    --theme-dark : #{$theme-dark};
    --theme-body-bg : #{$theme-body-bg};
    --theme-text : #{$theme-text};
    --theme-border : #{$theme-border};
    --theme-text-light : #{$theme-text-light};
    --theme-border-on : #{$theme-border-on};

    font-family: var(--font-body);
    font-weight: var(--font-regular);
    font-size: var(--font-rg);
    line-height: var(--line-height-body);
    background-color: var(--theme-body-bg);
    color: var(--theme-text);
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-heading);
    line-height: var(--line-height-heading);
    font-weight: var(--font-bold);
}

a {
    text-decoration: none !important;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

header {
    transition: all 0.5s linear;
    z-index: 99;
    position: relative;

    &[data-navbar="sticky-top"] {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    &.sticky-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: navbarReveal .5s linear;
        background-color: var(--theme-body-bg);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    @keyframes navbarReveal {
        0% {
            transform: translateY(-100%);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

// Form
label {
    color: $theme-color-3;

    &.form-label {
        margin-bottom: 12px;
    }
}

input:not([type="checkbox"]):not([type="radio"]),
select,
textarea {
    border-radius: 5px;
    padding: 15px;
    min-height: 56px;
    border: 1px solid #DBE0EB;
    color: $theme-color-3;

    @media screen and (max-width:640px) {
        padding: 10px;
        min-height: 42px;
    }

    &.form-control,
    &.form-select {
        color: $theme-text;

        &::placeholder {
            color: $theme-text;
            opacity: 1;
        }

        &:focus {
            border-color: $theme-color-3;
            box-shadow: none;
        }
    }

    option {
        color: $theme-color-3;
    }
}

button {
    &.btn-theme {
        line-height: 1.6;
    }
}

.select-input {
    input {
        min-height: 42px !important;
    }

    >div {
        min-width: 110px;
    }

    input {
        min-height: 33px;
    }

    &.country-wise {
        >div {
            min-width: 255px;
        }

        input {
            min-height: 34px !important;
        }
    }
}

.input-group {
    width: 110px;
    user-select: none;

    @media screen and (max-width:480px) {
        width: 100%;
    }

    input {
        min-height: 46px;
    }

    &-text {
        background-color: transparent;
        cursor: pointer;
    }

    [readonly] {
        background-color: transparent;
        text-align: center;
        padding: 0px !important;
        flex-grow: 0;
        min-width: 30px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        color: $theme-color-3 !important;
        font-weight: $font-medium;
        min-height: 46px !important;

        @media screen and (max-width:480px) {
            min-width: calc(100% - 80px);
        }

        &:focus {
            border-top-color: #ced4da !important;
            border-bottom-color: #ced4da !important;
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    >* {
        &:first-child {
            border-right-color: transparent;
            padding-right: 6px;
            padding-left: 20px;
        }

        &:last-child {
            border-left-color: transparent;
            padding-left: 6px;
            padding-right: 20px;
        }
    }
}

.font-mdx {
    font-size: $font-mdx;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}