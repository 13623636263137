.theme-cta {
    border-radius: $rounded-lg;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    flex-direction: column;
    background-color: #103FB9;
    color: var(--theme-white);
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: 992px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding-left: 35px;
        padding-right: 35px;

        .cta-image {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.position-center {
        align-items: center;
        text-align: center;
        .cta-image {
            margin: 0 auto;
        }
    }
    .cta-image {
        width: 120px;
        height: auto;
        img {
            width: 100%;
        }
    }
    .cta-content {
        width: 100%;
        max-width: 768px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .main-text {
            font-size: var(--font-4x);
            font-weight: var(--font-bold);
            @media screen and (max-width:1200px) {
                font-size: var(--font-2x);
            }
            @media screen and (max-width:480px) {
                font-size: var(--font-lg);
            }
        }
        .sub-text {
            color: var(--theme-white);
            font-size: var(--font-md);
            @media screen and (max-width:480px) {
                font-size: var(--font-rg);
            }
        }
    }
    .cta-actions {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        flex-wrap: wrap;
    }
}

.theme-cta-2 {
    background-image: url(../img/vectors/cta-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    border-radius: 20px;
    gap: 80px;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        text-align: center;
        gap: 40px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($theme-color-3, .5);
        z-index: -1;
        border-radius: inherit;
    }
    .cta-quote {
        .icon {
            margin-bottom: 20px;   
        }
        .quote {
            margin-bottom: 30px;
            color: #fff;
            font-size: $font-lg;
            font-weight: $font-semi;
        }
        .person {
            font-size: 22px;
            color: #fff;
            gap: 16px;
            font-weight: $font-medium;
            @media screen and (max-width: 1200px) {
                justify-content: center;
            }
        }
    }
    .cta-action {
        flex-shrink: 0;
    }
}