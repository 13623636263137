.theme-logo {
    font-family: var(--font-heading);
    font-size: var(--font-md);
    font-weight: var(--font-bold);
    display: inline-flex;
    align-items: center;
    gap: 8px;
    > a {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        color: var(--theme-text);
    }
    .icon {
        font-size: var(--font-lg);
        color: var(--theme-color-1);
    }
    img {
        max-height: 40px;
        width: auto;
    }
}