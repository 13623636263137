.widget {
    margin-bottom: 40px;
    .widget-title {
        font-weight: 600;
        margin-bottom: 24px;
        color: var(--theme-white);
    }

    .widget-content {
        color: var(--theme-color-4);
        .theme-logo {
            margin-bottom: 30px;
        }
        .address {
            span {
                display: block;
            }
        }
        .socials {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }


    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 12px;

        li {
            display: flex;
            align-items: center;
            gap: 12px;

            a {
                color: $theme-color-4;
                text-decoration: none;

                &:hover {
                    color: $theme-color-2;
                }
            }
        }
    }
}