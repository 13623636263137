.swiper-slide img {
    height: auto;
    max-width: 100%;
}
.swiperView {
    position: relative;
    .swiperScreens{
        position: absolute;
        top: 6%;
        left: 12.5%;
        width: 75%;
        border-radius: $rounded;
        overflow: hidden;
        height: calc(100% - 55px);
    }
    &.display {
        .swiperScreens {
            top: 6%;
            left: auto;
            right: 3%;
            width: 94%
        }
    }
}
.mySwiper {
    width: 100%;
    max-width: 566px;
    margin-top: 30px;
    padding-bottom: 55px;
    &.style-1 {
        margin-right: 0;
        margin-left: auto;
        max-width: 490px;
        @media screen and (max-width:1200px) {
            margin-right: auto;
        }
    }
    &.style-2 {
        max-width: 844px;
        margin-top: 40px;
        padding-bottom: 0;
        img {
            border-radius: 10px;
            width: 100%;
        }
        .swiper-slide {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-radius: 10px;
                background-color: rgba($theme-color-3, .3);
            }
        }
        .swiper-slide-thumb-active {
            &:after {
                opacity: 0;
            }
            img {
                border-color: #1AC75F;
            }
        }
    }
    img {
        border-radius: $rounded;
        border: 2px solid #A1A8C6;
    }
    .swiper-slide-thumb-active {
        img {
            border-color: var(--theme-color-2);
            border-width: 2px;
        }
    }

    .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
            width: 25px;
            height: 8px;
            background-color: #2DB4EE;
            opacity: 0.2;
            border-radius: 0;
            margin: 0;
            &:first-child {
                border-top-left-radius: $rounded-lg;
                border-bottom-left-radius: $rounded-lg;
            }
            &:last-child {
                border-top-right-radius: $rounded-lg;
                border-bottom-right-radius: $rounded-lg;
            }
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
            border-radius: $rounded-lg;
            transform: scale(1.4)
        }
    }
}

.review-slider {
    padding-bottom: 60px;
    .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #1C1456;
            border: 1px solid #35298D;
            opacity: 1;
            &-active {
                width: 16px;
                height: 16px;
                border: 1px solid #7B6BF4;
                background: linear-gradient(180deg, #614DFC 0%, #3F29EA 100%);
            }
        }
    }
}

.float-image {
    z-index: 9;
    width: 175px;
    @media screen and (max-width:1200px) {
        display: none;
    }
}
.image-left {
    padding-left: 160px;
    max-width: 100%;
    @media screen and (max-width:1200px) {
        padding-left: 0px;
    }
    > img {
        margin-left: -100px;
        margin-bottom: 50px;
    }
}

.brand-slider {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #EEF6FF99;
    @media screen and (max-width: 991px) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(251.58deg, #CAE9F5 0.07%, rgba(37, 148, 195, 0.4) 25.46%, #CAE9F5 75.02%);
    }
    &:before {
        top: 0;
    }
    &:after {
        bottom: 0;
    }
}

.pos-swiper {
    @media screen and (max-width: 1200px) {
        padding-bottom: 90px;
    }
    .pos-swiper-button {
        &-prev,
        &-next {
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F5F3FD;
            border-radius: 60px;
            position: absolute;
            top: 50%;
            left: -90px;
            transform: translateY(-50%);
            cursor: pointer;
            padding-top: 3px;
            padding-right: 2px;
            @media screen and (max-width: 1200px) {
                top: auto;
                bottom: 0;
                left: 50%;
                transform: translateX(-100%);
            }
            &:after {
                font-size: 12px;
                color: #5E4BED;
                margin-top: 5px;
            }
            &:hover {
                background-color: #e4defd;
            }
        }
        &-next {
            left: auto;
            right: -90px;
            padding-right: 0;
            padding-left: 2px;
            @media screen and (max-width: 1200px) {
                right: 50%;
                transform: translateX(100%);
            }
        }
    }
}