.btn-theme {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 8px;
    text-decoration: none;
    color: var(--theme-white);
    background-color: var(--theme-color-1);
    transition: $trans-25;
    border-radius: $rounded;
    padding: $padding-y $padding-x;
    border: 0;
    flex-shrink: 0;
    font-size: 18px;
    flex-wrap: nowrap;
    font-weight: $font-medium;
    @media screen and (max-width: 768px) {
        padding: 12px 18px;
        font-size: var(--font-rg);
    }
    > * {
        flex-shrink: 0;
    }
    svg path {
        transition: $trans-25;
    }
    &:hover {
        background-color: darken($theme-color-1, 10%);
        color: var(--theme-white);
        transform: translateY(-3px);
        svg path {
            stroke: var(--theme-white);
        }
    }
    &.btn-theme-lg {
        font-size: var(--font-md);
        padding: $padding-y-lg $padding-x-lg;
    }
    &.btn-theme-md {
        padding: $padding-y-md $padding-x-md;
    }
    &.btn-theme-sm {
        padding: $padding-y-sm $padding-x-sm;
    }
    &.btn-theme-iconBox {
        padding: 10px 35px;
        font-weight: $font-regular;
    }
    &.btn-theme-light {
        color: var(--theme-color-1);
        background-color: var(--theme-color-1-light);
        &:hover {
            color: var(--theme-white);
            background-color: var(--theme-color-1);
        }
    }
    &.btn-theme-outline {
        border: 1px solid var(--theme-color-1);
        color: var(--theme-color-1);
        background-color: transparent;
        &:hover {
            color: var(--theme-white);
            background-color: var(--theme-color-1);
        }
    }
    &.btn-theme-square {
        height: 40px;
        width: 40px;
    }
    &.btn-theme-white {
        border-color: var(--theme-white);
        background-color: var(--theme-white);
        color: var(--theme-color-1);
        &:hover {
            svg path {
                stroke: var(--theme-color-1);
            }
        }
        &.btn-theme-outline {
            background-color: transparent;
            color: var(--theme-white);
            &:hover {
                background-color: var(--theme-white);
                color: var(--theme-color-1);
            }
        }
    }
    &.btn-extra-wide {
        min-width: 328px;
        padding-left: 100px;
        padding-right: 100px;
        flex-shrink: 1;
        * {
            flex-shrink: 1;
        }
        @media screen and (max-width: 768px) {
            min-width: 280px;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}