.contact-address {
    border-radius: 10px;
    background-color: #103FB9;
    width: 372px;
    background-image: url('../img/vectors/contact-address-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 42px;
    color: #fff;

    @media screen and (max-width:480px) {
        padding: 30px 20px;
    }

    .widget {
        margin-bottom: 0;

        ul li {
            align-items: start;
        }
    }
}

.modal .contact-form {
    @media screen and (max-width:576px) {
        box-shadow: none;
    }
}

.contact-form {
    padding: 60px;
    padding-left: 246px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-left: 186px;
    position: relative;

    @media screen and (max-width:992px) {
        margin-left: 0;
        padding: 15px;
    }

    @media screen and (max-width:480px) {
        padding: 0px;
    }

    .contact-address {
        position: absolute;
        left: 0;
        transform: translateX(-186px);

        @media screen and (max-width:992px) {
            position: static;
            height: auto;
            width: 100%;
            top: auto;
            transform: none;
            margin-bottom: 40px;
        }

        .title {
            font-weight: $font-medium;
            font-size: $font-lg;
            padding-bottom: 20px;
            border-bottom: 2px solid rgba(#FCFDFF, .1);
        }
    }
}

.contact-map {
    min-height: 610px;
    overflow: hidden;

    @media screen and (max-width: 575px) {
        min-height: 510px;
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .add-info {
        position: relative;
        min-width: 220px;

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            height: 0;
            width: 0;
            border: 15px solid transparent;
            border-top-color: #fff;
            border-top-width: 30px;
        }
    }
}