.review-card {
    .review {
        padding: 20px;
        background-color: #0B1230;
        .stars {
            margin-bottom: 25px;
            display: flex;
            gap: 6px;
        }
        .text {
            font-style: italic;
            font-size: 22px;
            line-height: 160%;
            color: #A5ACB9;
            cursor: pointer;
        }
    }
    .client{
        display: flex;
        gap: 12px;
        margin-top: 20px;
        align-items: center;
        .avatar {
            height: 60px;
            width: 60px;
            border-radius: $rounded;
            overflow: hidden;
        }
        .info {
            .name {
                color: var(--theme-white);
                font-size: 20px;
                font-weight: var(--font-medium);
                opacity: 0.8;
            }
        }
    }
}

.review-slider {
    .swiper-wrapper {
        align-items: flex-end;
        .swiper-slide-active {
            .review-card {
                .review {
                    .stars {
                        opacity: 1;
                    }
                    .text {
                        color: var(--theme-white);
                        cursor: pointer;
                    }
                }
                .client {
                    .info {
                        .name {
                            opacity: 1;
                        }
                        .position {
                            color: var(--theme-color-4);
                        }
                    }
                }
            }
        }
    }
}

.singleReviewCard {
    display: flex;
    align-items: center;
    gap: 45px;
    padding: 40px 98px;
    padding-left: 0;
    background-color: #F5FAFF;
    margin-left: 98px;
    @media screen and (max-width: 992px) {
        flex-direction: column;
        text-align: center;
        padding: 40px;
        margin-left: 0;
    }
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    .reviewer {
        width: 318px;
        flex-shrink: 0;
        margin-left: -98px;
        @media screen and (max-width: 992px) {
            width: 180px;
            margin-left: 0;
        }
    }
    .review {
        &QuoteIcon {
            margin-bottom: 20px;
            @media screen and (max-width: 576px) {
                img {
                    height: 40px;
                }
            }
        }
        &Text {
            margin-bottom: 24px;
            font-size: 22px;
            font-style: italic;
            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
            @media screen and (max-width: 576px) {
                font-size: 16px;
            }
        }
        &Author {
            .author {
                &Name {
                    font-weight: $font-semi;
                    font-size: 28px;
                    color: $theme-color-3;
                    @media screen and (max-width: 768px) {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}