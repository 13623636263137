.pricing-table {
    .text-success {
        color: #1AC75F !important;
    }

    table {
        width: 100%;
        color: $theme-color-3;

        tr {
            td {
                min-width: 240px;
                width: 240px;
                padding: 0;
                background-color: #F3F8FF;

                &:not(:first-child) {
                    text-align: center;
                }
            }
        }

        thead {
            tr {

                &:nth-child(1),
                &:nth-child(4),
                &:nth-child(6) {
                    font-weight: $font-semi;
                    font-size: 24px;

                    @media screen and (max-width: 768px) {
                        font-size: 20px;
                    }

                    td {
                        padding: 16px 0;
                    }
                }

                &:nth-child(2) {
                    td {
                        font-size: $font-md;
                        color: #fff;
                        padding: 20px;
                        text-align: left;
                        height: 200px;
                        vertical-align: top;

                        img {
                            max-height: 14px;
                            max-width: 20px;
                        }

                        @media screen and (max-width: 768px) {
                            font-size: $font-rg;
                        }

                        &:nth-child(1) {
                            background-color: #EC9037;
                            border-width: 0;
                        }

                        &:nth-child(2) {
                            background-color: #14BA7F;
                            border-width: 0;
                        }

                        &:nth-child(3) {
                            background-color: #2395E3;
                            border-width: 0;
                        }
                    }
                }

                &:nth-child(3),
                &:nth-child(5) {
                    td {
                        padding: 12px;
                        color: $theme-text;
                    }
                }

                &:nth-child(4),
                &:nth-child(6) {
                    td:nth-child(1) {
                        background-color: #FFF0E1;
                        color: #EC9037;
                    }

                    td:nth-child(2) {
                        background-color: #E0FFF4;
                        color: #14BA7F;
                    }

                    td:nth-child(3) {
                        background-color: #ECF7FF;
                        color: #2395E3;
                    }
                }

                &:nth-child(1) {
                    td {
                        padding: 36px 10px;
                        font-size: 36px;
                        font-weight: $font-medium;

                        .theme-icon {
                            margin-bottom: 12px;
                        }

                        &:nth-child(1) {
                            padding-left: 68px;
                            padding-right: 68px;
                            vertical-align: middle;

                            svg,
                            img {
                                max-width: 100%;
                                max-height: 420px;
                            }

                            min-width: 539px;
                            width: 539px;

                            @media screen and (max-width: 1400px) {
                                min-width: 360px;
                                width: 360px;
                            }

                            @media screen and (max-width: 768px) {
                                min-width: 280px;
                                width: 280px;
                            }

                            @media screen and (max-width: 480px) {
                                min-width: 200px;
                                width: 200px;
                            }
                        }

                        &:nth-child(2) {
                            .theme-icon {
                                background-color: #FFF0E1;
                                color: #EC9037;
                            }
                        }

                        &:nth-child(3) {
                            .theme-icon {
                                background-color: #E0FFF4;
                                color: #14BA7F;
                            }
                        }

                        &:nth-child(4) {
                            .theme-icon {
                                background-color: #ECF7FF;
                                color: #2395E3;
                            }
                        }
                    }
                }

                td {
                    border-color: #DBE0EB;
                    border-left-width: 1px;
                    border-right-width: 1px;
                    border-top-width: 1px;
                    background-color: transparent;
                    text-align: center;

                    &:nth-child(1) {
                        min-width: 240px;
                        width: 240px;
                    }

                    .price-cat {
                        margin-bottom: 8px;
                        display: block;

                        &list {
                            font-size: 14px;
                            margin-top: 8px;

                            ul {
                                list-style-type: disc;
                                padding-left: 17px;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                            }
                        }
                    }

                    .d-flex {
                        gap: 10px;
                    }
                }
            }
        }

        tbody {
            border-bottom: 1px solid #DBE0EB;

            tr {
                border-color: transparent;

                &:nth-child(1) {
                    td:nth-child(1) {
                        min-width: 539px;
                        width: 539px;

                        @media screen and (max-width: 1400px) {
                            min-width: 360px;
                            width: 360px;
                        }

                        @media screen and (max-width: 768px) {
                            min-width: 280px;
                            width: 280px;
                        }

                        @media screen and (max-width: 480px) {
                            min-width: 200px;
                            width: 200px;
                        }
                    }
                }
            }

            >tr:not(.features, .static-features) {
                >td {
                    padding: 16px 30px;
                    border-left-color: #DBE0EB;
                    border-right-color: #DBE0EB;
                    border-left-width: 1px;
                    border-right-width: 1px;

                    @media screen and (max-width: 768px) {
                        padding: 8px 15px;
                    }

                    &.title {
                        font-weight: $font-medium;
                        font-size: 20px;
                        cursor: pointer;

                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }

                        .icon {
                            margin-right: 10px;
                            transition: .05s linear;
                        }

                        &.active {
                            .icon {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
    }

    .emptyRow {
        height: 12px;

        td {
            border-left-width: 1px;
            border-right-width: 1px;
            padding: 0 !important;
            background-color: transparent;
        }
    }

    .features,
    .static-features {
        &.active+.emptyRow {
            display: none;
        }

        td {
            display: none;
            background-color: transparent;
        }

        &.active {
            td {
                display: table-cell;
            }
        }

        table {
            tr {
                border-color: #DBE0EB;
                border-bottom-width: 1px;

                &:last-child {
                    border-bottom-width: 0px;
                }

                td {
                    border-color: #DBE0EB;
                    border-left-width: 1px;
                    border-right-width: 1px;
                }
            }
        }
    }

    .static-features {
        table tr td {
            &:not(:first-child) {
                color: $theme-text;
            }
        }
    }

    .bg-transparent {
        td {
            background-color: transparent !important;
            border-color: #DBE0EB;
        }
    }
}

.table-filter {
    display: flex;
    align-items: center;
    gap: 60px;
    margin-bottom: 80px;

    @media screen and (max-width: 1400px) {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .filterInput {
        display: flex;
        align-items: center;
        gap: 20px;

        .form-control {
            text-align: center;
            border-left-width: 0;
            border-right-width: 0;
            padding: 0;

            &:focus {
                border-color: #ced4da !important;
            }
        }

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: 480px) {
            gap: 10px;

            .select-input {
                >div {
                    width: calc(100vw - 30px);
                }
            }
        }
    }

    .filterLabel {
        flex-shrink: 0;
    }
}

.pricing-single-title {
    color: $theme-color-3;
    margin-bottom: 60px;

    h2 {
        font-size: $font-4x;

        @media screen and (max-width: 1200px) {
            font-size: $font-2x;
        }

        @media screen and (max-width: 768px) {
            font-size: $font-lg;
        }
    }
}