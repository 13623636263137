// Classes
.section-gap {
    padding-top: $section-gap;
    padding-bottom: $section-gap;

    &.pb-0 {
        padding-bottom: 0;
    }

    @media screen and (max-width:1200px) {
        padding-top: $section-gap-xs;
        padding-bottom: $section-gap-xs;
    }

    &-xxs {
        padding-top: $section-gap-xxs;
        padding-bottom: $section-gap-xxs;
    }

    &-sm {
        padding-top: $section-gap-sm;
        padding-bottom: $section-gap-sm;

        @media screen and (max-width:1200px) {
            padding-top: $section-gap-xxs;
            padding-bottom: $section-gap-xxs;
        }
    }

    &-md {
        padding-top: $section-gap-md;
        padding-bottom: $section-gap-md;

        @media screen and (max-width:1200px) {
            padding-top: $section-gap-sm;
            padding-bottom: $section-gap-sm;
        }
    }

    &-lg {
        padding-top: $section-gap-lg;
        padding-bottom: $section-gap-lg;

        @media screen and (max-width:1200px) {
            padding-top: $section-gap;
            padding-bottom: $section-gap;
        }
    }

    &-xl {
        padding-top: $section-gap-xl;
        padding-bottom: $section-gap-xl;

        @media screen and (max-width:1200px) {
            padding-top: $section-gap-md;
            padding-bottom: $section-gap-md;
        }
    }

    &-top {
        padding-top: $section-gap;

        @media screen and (max-width:1200px) {
            padding-top: $section-gap-xs;
        }

        &-xxs {
            padding-top: $section-gap-xxs;
        }

        &-sm {
            padding-top: $section-gap-sm;

            @media screen and (max-width:1200px) {
                padding-top: $section-gap-xxs;
            }
        }

        &-md {
            padding-top: $section-gap-md;

            @media screen and (max-width:1200px) {
                padding-top: $section-gap-sm;
            }
        }

        &-lg {
            padding-top: $section-gap-lg;

            @media screen and (max-width:1200px) {
                padding-top: $section-gap;
            }
        }

        &-xl {
            padding-top: $section-gap-xl;

            @media screen and (max-width:1200px) {
                padding-top: $section-gap-md;
            }
        }
    }

    &-bottom {
        padding-bottom: $section-gap;

        @media screen and (max-width:1200px) {
            padding-bottom: $section-gap-xs;
        }

        &-xxs {
            padding-bottom: $section-gap-xxs;
        }

        &-sm {
            padding-bottom: $section-gap-sm;

            @media screen and (max-width:1200px) {
                padding-bottom: $section-gap-xxs;
            }
        }

        &-md {
            padding-bottom: $section-gap-md;

            @media screen and (max-width:1200px) {
                padding-bottom: $section-gap-sm;
            }
        }

        &-lg {
            padding-bottom: $section-gap-lg;

            @media screen and (max-width:1200px) {
                padding-bottom: $section-gap;
            }
        }

        &-xl {
            padding-bottom: $section-gap-xl;

            @media screen and (max-width:1200px) {
                padding-bottom: $section-gap-md;
            }
        }
    }
}

.rounded {
    border-radius: $rounded;

    &-md {
        border-radius: $rounded-md;
    }

    &-lg {
        border-radius: $rounded-lg;
    }

    &-full {
        border-radius: $rounded-full;
    }
}

.theme-border {
    border: 1px solid var(--theme-border);
}

.section-title {
    margin-bottom: 40px;

    &.mb-lg {
        margin-bottom: 60px;
    }

    &.mb-xl {
        margin-bottom: 80px;
    }

    &.mb-0 {
        margin-bottom: 0;
    }

    .sub-title {
        text-transform: uppercase;
        color: var(--theme-color-2);
        margin-bottom: 10px;
        font-weight: var(--font-semi);
        letter-spacing: 0.05em;
    }

    .main-title {
        color: var(--theme-color-3);
        font-size: var(--font-4x);
        font-weight: var(--font-bold);
        line-height: var(--line-height-heading);
        margin-bottom: 20px;

        @media screen and (max-width: 1200px) {
            font-size: var(--font-2x);
        }

        @media screen and (max-width: 480px) {
            font-size: var(--font-lg);
        }

        &.title-sm {
            font-size: var(--font-2x);
        }

        &.title-lg {
            font-size: 87px;

            @media screen and (max-width: 1200px) {
                font-size: 60px;
            }

            @media screen and (max-width: 1200px) {
                font-size: 48px;
            }
        }

        &.gap-lg {
            margin-bottom: 30px;
        }

        &.fw-medium {
            font-weight: $font-medium;
        }
    }

    .text {
        width: 100%;
        max-width: 570px;
        font-size: 18px;

        @media screen and (max-width:480px) {
            font-size: var(--font-rg);
        }

        &.text-lg {
            font-size: 20px;
        }

        &.text-sm {
            font-size: 16px;
        }

        &.mw-100 {
            max-width: 100%;
        }

        ul {
            margin-top: 8px;
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 9px;
            border-top: 1px solid #DBE0EB;

            li {
                padding-left: 18px;
                color: $theme-color-3;
                padding-left: 0;

                &:before {
                    content: "\2022";
                    color: $theme-color-2;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: 0;
                }
            }
        }
    }

    &.light {
        .main-title {
            color: var(--theme-white);
        }

        .text {
            color: $theme-color-4;
        }
    }
}

.highlight {
    color: var(--theme-color-3);

    &.theme-color-2 {
        color: var(--theme-color-2);
    }

    &.underline {
        text-decoration: underline;
    }

    &.bold {
        font-weight: var(--font-bold);
    }

    &.light {
        color: var(--theme-color-4);
    }
}

.container-fluid {
    padding-left: 195px;
    padding-right: 195px;
    max-width: 1920px;

    @media screen and (max-width: 1600px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.bg-dark {
    background-color: var(--theme-dark);
}

.gap-20 {
    gap: 20px
}

.mb-20 {
    margin-bottom: 20px;
}

.pt-15 {
    padding-top: 15px;
}

.p-40 {
    padding: 40px !important;
}

.mt-40 {
    margin-top: 40px;
}

.mt-60 {
    margin-top: 60px;
}

.bg-half-dark {
    position: relative;
    z-index: 0;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0%;
        height: 50%;
        width: 100%;
        background: #060414;
        z-index: -1;
    }
}

.bg-theme-black {
    background-color: var(--theme-black);
}

.bg-theme-dark {
    background-color: #080D23;
}

.img-overflow {
    max-width: calc(100% + 100px);
    height: auto;

    &.left-side {
        margin-left: -100px;
    }

    &.right-side {
        margin-right: -100px;

        &-lg {
            margin-right: -200px;
        }
    }

    @media screen and (max-width:1600px) {
        max-width: 100%;

        &.left-side {
            margin-left: 0;
        }

        &.right-side {
            margin-right: 0;

            &-lg {
                margin-right: 0;
            }
        }
    }
}

.text-color {
    &-1 {
        color: $theme-color-1;
    }

    &-2 {
        color: $theme-color-2;
    }

    &-3 {
        color: $theme-color-3;
    }
}

.fw-medium {
    font-weight: $font-medium;
}

.cursor-pointer {
    cursor: pointer;
}

.back-to-top {
    z-index: 9999;
}

// BS Accordion
.accordion {
    --bs-accordion-active-color: #{$theme-color-1};
    --bs-accordion-active-bg: #{$theme-white};
    --bs-accordion-btn-padding-x: 20px;
    --bs-accordion-btn-padding-y: 20px;
    --bs-accordion-btn-color: #{$theme-color-3};
    --bs-accordion-color: #{$theme-text};
    --bs-accordion-transition: color 0.05s ease-in-out,
        background-color 0.05s ease-in-out,
        border-color 0.05s ease-in-out,
        box-shadow 0.05s ease-in-out,
        border-radius 0.05s ease;
    border-radius: 10px;
}

.accordion-flush .accordion-item {
    border-radius: 10px;
    border: 1px solid #DBE0EB;

    &:first-child {
        border-top: 1px solid #DBE0EB;
    }

    &:last-child {
        border-bottom: 1px solid #DBE0EB;
    }

    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 10px;
    box-shadow: none;
    transition: $trans-25;
}

.accordion-flush .accordion-item .accordion-button.collapsed {
    padding-bottom: 20px;
}

.accordion-button {
    font-weight: $font-medium;
    font-size: 22px;
    padding-bottom: 10px;
}

.accordion-body {
    padding-top: 0px;
    width: 75%;
    line-height: 1.6;

    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%2321355C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%235E4BED' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.collapsing {
    transition: height 0.15s ease;
}

// BS Breadcrumb
.breadcrumb {
    --bs-breadcrumb-divider: url('../img/icons/breadcrumb.svg');

    &-item {
        &:before {
            padding-right: 10px;
        }

        padding-left: 7px;
        color: $theme-color-3;
        font-weight: $font-medium;
        font-size: 22px;

        @media screen and (max-width: 768px) {
            font-size: $font-rg;
        }

        a {
            color: $theme-text;

            &:hover {
                color: $theme-color-1;
            }
        }

        &.active {
            color: $theme-color-3;
        }
    }
}

// BS Tab
.nav {
    --bs-nav-pills-link-active-bg: #{$theme-color-1};
    --bs-nav-link-color: #{$theme-color-1};
    --bs-nav-link-hover-color: #{$theme-color-1};
}

.partners-tab-pill {
    --bs-nav-link-padding-x: 24px;
    --bs-nav-link-padding-y: 24px;
    --bs-nav-pills-border-radius: 0px;
    border-radius: 100px;
    border: 1px solid $theme-color-1;
    margin-bottom: 80px;

    @media screen and (max-width: 576px) {
        --bs-nav-link-padding-x: 12px;
        --bs-nav-link-padding-y: 16px;
        border: none;
    }

    .nav-item {
        a {
            width: 415px;
            font-weight: $font-semi;
            text-align: center;

            @media screen and (max-width:1600px) {
                width: 300px;
            }

            @media screen and (max-width: 768px) {
                width: auto;
                min-width: 240px;
            }

            @media screen and (max-width: 576px) {
                border-radius: 100px;
                background: rgba($theme-color-1, .1);
                margin-bottom: 8px;
            }
        }

        &:first-child {
            a {
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
            }
        }

        &:last-child {
            a {
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
            }
        }
    }
}

.partners-inner-tab-pill {
    --bs-nav-link-padding-x: 20px;
    --bs-nav-link-padding-y: 20px;
    --bs-nav-pills-border-radius: 5px;

    @media screen and (max-width:1200px) {
        margin-bottom: 40px;
        --bs-nav-link-padding-x: 16px;
        --bs-nav-link-padding-y: 16px;
    }

    @media screen and (max-width:576px) {
        --bs-nav-link-padding-x: 8px;
        --bs-nav-link-padding-y: 12px;
    }

    .nav-item {
        margin-bottom: 24px;

        @media screen and (max-width:1200px) {
            margin-right: 10px;
            margin-bottom: 12px;
        }

        @media screen and (max-width:576px) {
            width: 100%;
        }

        a {
            color: $theme-text;
            border: 1px solid #DBE0EB;
            font-size: 22px;
            font-weight: $font-medium;
            display: flex;
            align-items: center;
            gap: 12px;

            @media screen and (max-width:1200px) {
                font-size: 18px;
            }

            @media screen and (max-width:576px) {
                font-size: 16px;
            }

            svg {
                height: 30px;
                width: 30px;
                flex-shrink: 0;

                @media screen and (max-width:576px) {
                    height: 20px;
                    width: 20px;
                }
            }

            &.active {
                border-color: $theme-color-1;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

// BS Gutter
.g-30 {
    --bs-gutter-y: 30px;
    --bs-gutter-x: 30px;
}

.gx-30 {
    --bs-gutter-x: 30px;
}

.gx-60 {
    --bs-gutter-x: 60px;
}

.gx-80 {
    --bs-gutter-x: 80px;
}

.gx-100 {
    --bs-gutter-x: 100px;
}

.g-60 {
    --bs-gutter-y: 60px;
    --bs-gutter-x: 60px;
}

@media screen and (max-width:1200px) {
    .g-30 {
        --bs-gutter-x: 24px;
    }

    .gx-30 {
        --bs-gutter-x: 24px;
    }

    .gx-60 {
        --bs-gutter-x: 24px;
    }

    .gx-80 {
        --bs-gutter-x: 24px;
    }

    .g-60 {
        --bs-gutter-x: 24px;
    }

    .gx-100 {
        --bs-gutter-x: 24px;
    }
}