.theme-list-style {
    list-style: none;
    margin: 0;
    padding: 0;
    &.style-1 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 10px;
        li {
            display: flex;
            gap: 10px;
            font-size: var(--font-sm);
            color: var(--theme-color-3);
            svg, img, .icon {
                flex-shrink: 0;
            }
        }
    }
    &.font-lg {
        li {
            font-size: 22px;
        }
    }
}