.timelineList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 38px;
    padding: 145px 0;
    position: relative;
    @media screen and (max-width: 1366px) {
        gap: 18px;
        padding: 110px 0;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding: 20px 0;
    }
    .startPoint, .endPoint {
        margin-bottom: 20px;
        @media screen and (max-width: 1600px) {
            max-width: 100px;
        }
        @media screen and (max-width: 1200px) {
            max-width: 140px;
            margin-bottom: 0;
        }
    }
    &.animateView {
        ul li .theme-icon img {
            animation: ScaleUp 0.75s linear;
            animation-direction: normal;
        }
        .startPoint {
            animation: ScaleUp 0.75s linear;
            animation-direction: normal;
            animation-delay: .25s;
        }
        .endPoint {
            animation: ScaleUp 0.75s linear;
            animation-direction: normal;
            animation-delay: 7s;
        }
    }
    ul {
        display: flex;
        padding-left: 10px;
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
        li {
            border-radius:100px;
            padding: 8px;
            position: relative;
            margin-left: 70px;
            @media screen and (max-width: 1899px) {
                margin-left: 40px;
            }
            @media screen and (max-width: 1366px) {
                margin-left: 30px;
                transform: translateX(-15px);
            }
            @media screen and (max-width: 1200px) {
                margin-top: 12px;
            }
            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            &:nth-child(1) {
                margin-left: 30px;
                .theme-icon {
                    &:after {
                        width: 34px;
                    }
                }
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border: 3px solid #E6ECF5;
                border-left-color: transparent;
                border-radius: inherit;
                transform: rotate(45deg);
                @media screen and (max-width: 1200px) {
                    // border-bottom-color: transparent;
                    border-left-color: #E6ECF5;
                }
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
            &:after {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                height: 90px;
                width: 3px;
                background-color: #E6ECF5;
                @media screen and (max-width: 1200px) {
                    display: none;
                }
            }
            .stepTitle {
                position: absolute;
                bottom: 100%;
                margin-bottom: 124px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                padding: 15px 30px;
                border-radius: 5px;
                color: $theme-color-3;
                font-weight: $font-medium;
                font-size: 20px;
                z-index: 1;
                background-color: #fff;
                @media screen and (max-width: 1899px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 1600px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 1366px) {
                    padding: 8px 16px;
                    font-size: 15px;
                }
                @media screen and (max-width: 1200px) {
                    left: 100%;
                    bottom: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-bottom: 0;
                    margin-left: 40px;
                }
                @media screen and (max-width: 768px) {
                    position: static;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0;
                    transform: none;
                    margin-top: 12px;
                }
            }
            .theme-icon {
                position: relative;
                @media screen and (max-width: 1600px) {
                    width: 60px;
                    height: 60px;
                    img, i {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    height: 22px;
                    width: 22px;
                    border-radius: 100px;
                    background-color: inherit;
                    bottom: calc(100% + 100px);
                    left: 50%;
                    margin-left: 1.5px;
                    transform: translateX(-50%);
                    border: 1px solid #fff;
                    @media screen and (max-width: 1600px) {
                        margin-left: 0;
                    }
                    @media screen and (max-width: 1200px) {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    height: 3px;
                    width: 74px;
                    background-color: #E6ECF5;
                    top: 50%;
                    right: calc(100% + 3px);
                    transform: translateY(-50%);
                    @media screen and (max-width: 1899px) {
                        width: 44px;
                    }
                    @media screen and (max-width: 1366px) {
                        width: 34px;
                    }
                    @media screen and (max-width: 1200px) {
                        left: calc(100% + 4px);
                    }
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                &:hover {
                    .stepTitle {
                        color: #fff;
                        transition: $trans-25;
                        background-color: inherit;
                    }
                }
            }
            &:nth-child(2n) {
                &:before {
                    border-left-color: #E6ECF5;
                    border-bottom-color: transparent;
                    @media screen and (max-width: 1200px) {
                        border-bottom-color: #E6ECF5;
                    }
                }
                &:after {
                    bottom: auto;
                    top: 100%;
                }
                .stepTitle {
                    margin-bottom: auto;
                    margin-top: 124px;
                    bottom: auto;
                    top: 100%;
                    @media screen and (max-width: 1200px) {
                        margin-top: auto;
                        margin-right: 40px;
                        right: 100%;
                        left: auto;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    @media screen and (max-width: 768px) {
                        margin: 0;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        transform: none;
                    }
                }
                .theme-icon {
                    &:before {
                        top: calc(100% + 100px);
                        bottom: auto;
                    }
                    &:after {
                        @media screen and (max-width: 1200px) {
                            right: calc(100% + 4px);
                            left: auto;
                        }
                    }
                }
            }
            &:nth-child(1) {
                .theme-icon img {
                    animation-delay: 1s;
                }
            }
            &:nth-child(2) {
                .theme-icon img {
                    animation-delay: 1.75s;
                }
            }
            &:nth-child(3) {
                .theme-icon img {
                    animation-delay: 2.5s;
                }
            }
            &:nth-child(4) {
                .theme-icon img {
                    animation-delay: 3.25s;
                }
            }
            &:nth-child(5) {
                .theme-icon img {
                    animation-delay: 4s;
                }
            }
            &:nth-child(6) {
                .theme-icon img {
                    animation-delay: 4.75s;
                }
            }
            &:nth-child(7) {
                .theme-icon img {
                    animation-delay: 5.5s;
                }
            }
            &:nth-child(8) {
                .theme-icon img {
                    animation-delay: 6.25s;
                }
            }
        }
    }
}


@keyframes ScaleUp {
    0% {
       transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}