.spinner {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: linear-gradient(to right, #ffffff 10%, rgba(0, 0, 0) 42%);
    position: relative;
    -webkit-animation: rotate 1.4s linear infinite;
    animation: rotate 1.4s linear infinite;
    transform: translateZ(0);
}

.spinner:before {
    content: "";
    width: 50%;
    height: 50%;
    background: #fff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
}

.spinner:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}