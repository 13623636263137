// Video
.video-text {
    font-size: var(--font-lg);
    @media screen and (max-width:768px) {
        font-size: var(--font-md);
    }
}

// Footer
.footer-bottom {
    border-top: 1px solid #100E24;
    padding: 30px 0px;
    color: #787E8A;
    &-menu {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 16px;
    }
    .d-flex {
        gap: 50px;
        @media screen and (max-width:768px) {
            gap: 16px;
        }
    }
    a {
        color: #787E8A;
        &:hover {
            color: var(--theme-color-2);
        }
    }
}

// Video
.video-icon {
    margin-top: -40px;
    @media screen and (max-width: 768px) {
        margin-top: -20px;
    }
}

.videopop {
    .modal-body {
        padding: 0;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        cursor: pointer;
    }
}

// z-Index
.z-index-n1 {
    z-index: -1;
}
.z-index-0 {
    z-index: 0;
}
.z-index-9 {
    z-index: 9;
}

// vectors
.hero-vector-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    max-width: 33%;
    img {
        max-width: 100%;
        height: auto;
    }
}
.hero-vector-2 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    max-width: 33%;
    img {
        max-width: 100%;
        height: auto;
    }
}
.video-vector-1 {
    position: absolute;
    right: calc(100% + 45px);
    top: 100%;
    transform: translateY(-100%);
    z-index: -1;
    @media screen and (max-width: 1200px) {
        display: none;
    }
}
.video-vector-2 {
    position: absolute;
    left: calc(100% + 45px);
    top: 50%;
    transform: translateY(-100%);
    z-index: -1;
    @media screen and (max-width: 1200px) {
        display: none;
    }
}
.slider-vector-1 {
    position: absolute;
    right: 0;
    top: -22%;
    z-index: -1;
}
.slider-vector-2 {
    position: absolute;
    left:0;
    bottom: 0;
    z-index: -1;
}
.cta-vector {
    z-index: -1;
}

// About Display
.about-display {
    &-list {
        li {
            &:not(:first-child) {
                padding-top: 60px;
            }
    
            &:not(:last-child) {
                padding-bottom: 60px;
                border-bottom: 1px solid #DBE8FB;
            }
        }
    }
    &-item {
        display: flex;
        gap: 30px;
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
        .theme-icon {
            background-color: #F87B2F;
            &.green {
                background-color: #29BA1B;
            }
        }
        .section-title {
            margin-right: 10px;
            flex-shrink: 0;
            margin-bottom: 0;
            .sub-title {
                margin-bottom: 5px;
                line-height: 1.5;
            }
            .main-title {
                margin-bottom: 0;
                line-height: 1.35;
            }
        }
        .text {
            font-size: $font-md;
            p {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }
}

// Pattern Background
.pattern-bg {
    background-image: url('../img/vectors/about-display-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 100px;
    padding-bottom: 100px;
}