.loyalty-steps-wrap {
    margin-bottom: 80px;
    @media screen and (max-width: 1199px) {
        overflow-x:  auto;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 40px;
    }
}

.loyalty-steps {
    margin-top: 120px;
    @media screen and (max-width: 1199px) {
        min-width: 1180px;
    }
    .tooltip {
        position: absolute;
        bottom: 100%;
        left: 0;
        transform: translateX(-50%);
        border: 1px solid $theme-color-1;
        border-radius: 6px;
        min-width: 180px;
        margin-bottom: 20px;
        background: $theme-body-bg;
        transition: .5s linear;
        min-width: 190px;
        &.first {
            transform: translateX(-24px);
            &:after {
                left: 24px;
            }
        }
        &.last {
            transform: translateX(calc(-100% + 24px));
            &:after {
                left: calc(100% - 24px);
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
            height: 18px;
            width: 18px;
            border-bottom-right-radius: 3px;
            border: 1px solid $theme-color-1;
            border-left-color: transparent;
            border-top-color: transparent;
            background: $theme-body-bg;
            transition: .5s linear;
        }

        >.title {
            background-color: $theme-color-1;
            color: #fff;
            padding: 8px 12px;
            font-size: 18px;
            border-radius: 3px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .numbers {
            padding: 12px;
            font-size: 14px;
            color: $theme-color-3;

            >*:nth-child(1) {
                border-right: 1px solid $theme-color-1;
                padding-right: 12px;
            }

            >*:nth-child(2) {
                padding-left: 12px;
            }

            .count {
                font-size: 18px;
                color: $theme-color-1;
                font-weight: $font-medium;
            }
        }

        .avg {
            padding: 12px;
            padding-top: 0;
            font-size: 12px;
            text-align: center;
            color: $theme-color-3;
        }
    }
    .step-dot {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: $theme-color-1;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%) scale(1.6);
        border: 1px solid #fff;
        box-sizing: content-box;
        z-index: 10;
        transition: all 0.5s linear;
    }
    .step-bar {
        height: 16px;
        width: 16px;
        background-color: $theme-color-1;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        z-index: 10;
        transition: all 0.5s linear;
        pointer-events: none;
    }
    .step {
        width: 100%;
        position: relative;
        display: block;
        height: 16px;
        background-color: #B9B2F1;
        flex-grow: 1;
        cursor: pointer;
        // margin-right: 24px;
        &:first-child {
            width: 20px;
            margin-right: 0;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            z-index: 1;
            span {
                left: 0;
                right: auto;
                transform: translateX(0);
            }
        }
        &:last-child {
            margin-right: 0;
            span {
                transform: translateX(0);
                &:after {
                    content: "+"
                }
            }
        }
        span {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 6px;
            transform: translateX(50%);
            &.active {
                font-weight: bold;
                @extend .text-color-3;
                transition: .3s linear;
            }
        }
        &.active {
            span {
                font-weight: bold;
                @extend .text-color-3;
                transition: .3s linear;
            }
        }
    }
}