.how-process-step {
    border: 1px solid transparent;
    border-radius: 20px;
    &:hover {
        border-color: #EBEDF0;
        background-color: #FAFBFB;
    }
    .vector {
        min-height: 190px;
        display: flex;
        align-items: center;
    }
    .theme-icon {
        background-color: #FFE7D9;
        color: #F87B2F;
        margin-top: 56px;
        font-size: $font-md;
        font-weight: $font-medium;
    }
    .title {
        font-size: $font-lg;
        font-weight: $font-semi;
        color: $theme-color-3;
        line-height: 1.4;
    }
}

.loyalty-counter-card {
    padding: 40px 36px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        width: 100%;
        border-radius: 10px;
    }
    &.border-orange {
        background: linear-gradient(180deg, rgba(255, 231, 217, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
        &:after {
            background-color: #F87B2F;
        }
    }
    &.border-green {
        background: linear-gradient(180deg, rgba(207, 255, 212, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
        &:after {
            background-color: #12B123;
        }
    }
    &.border-cyan {
        background: linear-gradient(180deg, rgba(214, 250, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        &:after {
            background-color: #1FB3C7;
        }
    }
    .counter-number {
        font-size: 70px;
        font-weight: $font-ebold;
        line-height: $line-height-heading;
        color: $theme-color-3;
        margin-bottom: 40px;
    }
    .title {
        font-size: $font-lg;
        color: $theme-color-3;
        margin-bottom: 20px;
        font-weight: $font-semi;
    }
}

.userCard {
    padding: 30px;
    padding-right: 150px;
    border-radius: 20px;
    border: 1px solid #EBEDF0;
    background-color: #FAFBFB;
    height: 100%;
    @media screen and (max-width: 1200px){
        padding-right: 30px;
    }
    .userInfo {
        display: flex;
        gap: 24px;
        @media screen and (max-width: 480px) {
            flex-direction: column;
            gap: 12px;
        }
        .userPic {
            border-radius: 999px;
        }
        .userText {
            .userName {
                font-weight: bold;
                font-size: $font-lg;
                color: $theme-color-3;
                margin-bottom: 8px;
            }
            .userData {
                ul li {
                    font-size: $font-sm;
                }
            }
        }
    }
    .userExtra {
        padding-top: 30px;
        p {
            font-size: 18px;
            color: $theme-color-3;
        }
        ul li {
            &:before {
                content: "\2022";
                color: $theme-color-2;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: 0;
            }
        }
    }
}

.loyalty-pricing-card {
    background-color: #FBFDFF;
    border: 1px solid rgba(#DBE0EB, 0.5);
    color: rgba($theme-color-3, 0.8);
    border-radius: 5px;
    margin-bottom: 24px;
    .card-header {
        padding: 30px;
        color: rgba($theme-color-3, 0.8);
        .main-title {
            font-size: 28px;
            font-weight: $font-semi;
            color: $theme-color-3;
            margin-bottom: 8px;
        }
        .member-count {
            font-weight: $font-medium;
            color: rgba($theme-color-3, 0.8);
            margin-bottom: 12px;
        }
        .price {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-weight: $font-medium;
            color: rgba($theme-color-3, 0.8);
            margin-bottom: 20px;
            span {
                font-size: 36px;
                color: $theme-color-1;
            }
        }
        &-alt {
            background-color: #F3F8FF;
        }
    }
    .card-body {
        .list-title {
            color: #14BA7F;
            background-color: #E0FFF4;
            padding: 12px 30px;
            font-weight: $font-medium;
        }
        ul {
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
            li {
                color: rgba($theme-color-3, 0.8);
                display: flex;
                gap: 10px;
                &:before {
                    content: url('../img/icons/loyalty-check.svg');
                    margin-top: 2px;
                }
            }
        }
    }
    .single-addon {
        border-bottom: 1px solid rgba(#DBE0EB, 0.5);
        &:last-child {
            border-bottom: none;
        }
        .title-text {
            font-size: 18px;
        }
    }
    input {
        height: 20px;
        width: 20px;
    }
    .text-green {
        color: #14BA7F !important;
    }
    .text-info {
        color: #2DB4EE !important;
    }
    .text-sm {
        font-size: 14px;
    }
    .p-30 {
        padding: 30px;
    }
    .fs-18 {
        font-size: 18px;
    }
}

.loyalty-calc-card {
    color: $theme-color-3;
    border: 1px solid rgba(#DBE0EB, 0.5);
    margin-right: -1px;
    height: 100%;
    @media screen and (max-width: 1200px) {
        margin-right: 0;
    }
    .card-header {
        padding: 20px;
        font-weight: $font-semi;
        font-size: 22px;
        background-color: #F3F8FF;
    }
    .loyalty-card-item {
        display: flex;
        padding: 16px;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        min-height: 90px;
        &:not(:last-child) {
            border-bottom: 1px solid rgba(#DBE0EB, 0.5);
        }
        &.min-h-auto {
            min-height: auto;
        }
        &.active {
            background-color: #14BA7F;
            color: #fff;
            font-size: 22px;
            min-height: 70px;
        }
        .count-wrap {
            border: 1px solid #DBE0EB;
            padding: 5px 10px;
            border-radius: 5px;
        }
        input {
            min-height: 40px;
            border: 0;
            width: 60px;
            padding: 5px;
            text-align: right;
            &:focus {
                outline: none;
            }
        }
    }
}