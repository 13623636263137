.quality-card {
    background-color: var(--theme-color-3);
    border-radius: $rounded-lg;
    padding: $padding-xl;
    margin-bottom: 294px;
    @media screen and (max-width: 480px) {
        margin-bottom: 280px;
        padding: 30px;
    }
    .card-image {
        margin-bottom: -176px;
        position: relative;
        @media screen and (max-width: 480px) {
            margin-bottom: -100px;
        }
        img {
            border-radius: $rounded-lg;
        }
    }
    .section-title.light {
        padding-right: 20px;
        @media screen and (max-width: 480px) {
            margin-bottom: 20px;
        }
        .text {
            color: #FCFDFF;
            opacity: 0.9;
            line-height: 160%;
            font-size: var(--font-md);
            @media screen and (max-width: 480px) {
                font-size: var(--font-regular);
            }
        }
    }
    .card-content {
        font-size: var(--font-sm);
        margin-bottom: 20px;
        color: var(--theme-color-3);
    }
}
.quality-inner-card {
    padding: $padding;
    background-color: var(--theme-body-bg);
    border-radius: $rounded-lg;
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    right: 70px;
    bottom: -117px;
    box-shadow: -3.225806713104248px 3.225806713104248px 8.0645170211792px 0px #316FF724;
    max-width: 180px;
    @media screen and (max-width: 480px) {
        margin-bottom: -60px;
        right: 15px;
    }
    &.padding-lg {
        padding: 20px;
    }
    .card-title {
        display: flex;
        gap: 14px;
        align-items: center;
        margin-bottom: 5px;
        color: var(--theme-color-3);
        font-weight: var(--font-semi);
        &.title-lg {
            font-size: 18px;
        }
    }
}